import React, { useState, KeyboardEvent } from 'react';
import { TextField, Button, Typography, Box, Link } from '@mui/material';

interface SignInFormProps {
    onSignIn: (email: string, password: string) => void;
    onSignUpClick: () => void;
    onResetPasswordClick: () => void;
}

export const SignInForm: React.FC<SignInFormProps> = ({ onSignIn, onSignUpClick, onResetPasswordClick }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSignIn(email, password);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSignIn(email, password);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom>サインイン</Typography>
            <TextField
                fullWidth
                label="メールアドレス"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                onKeyPress={handleKeyPress}
            />
            <TextField
                fullWidth
                label="パスワード"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                onKeyPress={handleKeyPress}
            />
            <Box sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        onResetPasswordClick();
                    }}
                >
                    パスワードをお忘れですか？
                </Link>
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                サインイン
            </Button>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="body2">
                    アカウントをお持ちでない方は
                    <Link
                        component="button"
                        variant="body2"
                        onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            onSignUpClick();
                        }}
                        sx={{ ml: 1 }}
                    >
                        サインアップ
                    </Link>
                </Typography>
            </Box>
        </form>
    );
};

export default SignInForm;
