import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

const SignUpEmailForm: React.FC<{
    onInitiateSignUp: (email: string) => void;
    onSignInClick: () => void;
}> = ({ onInitiateSignUp, onSignInClick }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onInitiateSignUp(email);
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                label="メールアドレス"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
                確認コードを送信
            </Button>
            <Button onClick={onSignInClick} fullWidth>
                サインインに戻る
            </Button>
        </form>
    );
};

export default SignUpEmailForm;
