import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slider,
    Switch,
    Snackbar
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ParameterSettingsProps {
    model: string;
    setModel: (model: string) => void;
    temperature: number;
    setTemperature: (temp: number) => void;
    maxTokens: number;
    setMaxTokens: (tokens: number) => void;
    topP: number;
    setTopP: (topP: number) => void;
    showTest: boolean;
    setShowTest: (show: boolean) => void;
    isRagMode: boolean;
    handleRagModeToggle: (checked: boolean) => void;
}

const ParameterSettings: React.FC<ParameterSettingsProps> = ({
    model,
    setModel,
    temperature,
    setTemperature,
    maxTokens,
    setMaxTokens,
    topP,
    setTopP,
    showTest,
    setShowTest,
    isRagMode,
    handleRagModeToggle
}) => {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleRagModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleRagModeToggle(event.target.checked);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ width: 300, p: 2, bgcolor: 'rgba(255, 255, 255, 0.9)' }}>
            <Typography variant="h5" component="h2" gutterBottom color="black">
                パラメーター設定
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="model-select-label" sx={{ color: 'black' }}>モデル</InputLabel>
                <Select
                    labelId="model-select-label"
                    value={model}
                    label="モデル"
                    onChange={(e) => setModel(e.target.value as string)}
                    sx={{ color: 'black', '& .MuiSelect-icon': { color: 'black' } }}
                >
                    <MenuItem value="amazon.nova-micro-v1:0">Nova Micro</MenuItem>
                    <MenuItem value="amazon.nova-lite-v1:0">Nova Lite</MenuItem>
                    <MenuItem value="amazon.nova-pro-v1:0">Nova Pro</MenuItem>
                    <MenuItem value="anthropic.claude-3-haiku-20240307-v1:0">Claude 3 Haiku</MenuItem>
                    <MenuItem value="anthropic.claude-3-haiku-20241022-v1:0">Claude 3.5 Haiku</MenuItem>
                    <MenuItem value="anthropic.claude-3-sonnet-20240229-v1:0">Claude 3 Sonnet</MenuItem>
                    <MenuItem value="anthropic.claude-3-5-sonnet-20240620-v1:0">Claude 3.5 Sonnet</MenuItem>
                    <MenuItem value="anthropic.claude-3-5-sonnet-20241022-v2:0">Claude 3.5 Sonnet v2</MenuItem>
                    <MenuItem value="meta.llama3-8b-instruct-v1:0">Meta Llama 3 8B Instruct</MenuItem>
                    <MenuItem value="cohere.command-r-v1:0">Cohere Command R</MenuItem>
                    <MenuItem value="cohere.command-r-plus-v1:0">Cohere Command R+</MenuItem>
                    <MenuItem value="mistral.mistral-small-2402-v1:0">Mistral Small (24.02)</MenuItem>
                </Select>
            </FormControl>
            <Typography gutterBottom color="black">Temperature: {temperature}</Typography>
            <Slider
                value={temperature}
                onChange={(_, newValue) => setTemperature(newValue as number)}
                aria-labelledby="temperature-slider"
                valueLabelDisplay="auto"
                step={0.1}
                marks
                min={0}
                max={1}
                sx={{ mb: 2, color: 'black' }}
            />
            <Typography gutterBottom color="black">Max Tokens: {maxTokens}</Typography>
            <Slider
                value={maxTokens}
                onChange={(_, newValue) => setMaxTokens(newValue as number)}
                aria-labelledby="max-tokens-slider"
                valueLabelDisplay="auto"
                step={100}
                marks
                min={100}
                max={4000}
                sx={{ mb: 2, color: 'black' }}
            />
            <Typography gutterBottom color="black">Top-P: {topP}</Typography>
            <Slider
                value={topP}
                onChange={(_, newValue) => setTopP(newValue as number)}
                aria-labelledby="top-p-slider"
                valueLabelDisplay="auto"
                step={0.01}
                marks
                min={0}
                max={1}
                sx={{ mb: 2, color: 'black' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography color="black">テストを表示</Typography>
                <Switch
                    checked={showTest}
                    onChange={(e) => setShowTest(e.target.checked)}
                    color="primary"
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <Typography color="black">RAGモード</Typography>
                <Switch
                    checked={isRagMode}
                    onChange={handleRagModeChange}
                    color="primary"
                />
            </Box>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    {isRagMode ? 'RAGモードに切り替えました。' : 'LLMモードに切り替えました。'}新しいセッションが開始されます。
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ParameterSettings;
