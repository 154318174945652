import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

const SignUpCodeForm: React.FC<{
    onConfirmSignUp: (code: string) => void;
    onResendCode: () => void;
}> = ({ onConfirmSignUp, onResendCode }) => {
    const [code, setCode] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onConfirmSignUp(code);
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                label="確認コード"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
                確認コードを検証
            </Button>
            <Button onClick={onResendCode} fullWidth>
                確認コードを再送信
            </Button>
        </form>
    );
};

export default SignUpCodeForm;
