import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { v4 as uuidv4 } from 'uuid';

const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION as string;
const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID as string;
const COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID as string;

const userPoolData = {
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_APP_CLIENT_ID
};

const userPool = new CognitoUserPool(userPoolData);

export const signIn = async (email: string, password: string): Promise<CognitoUserSession> => {
    const authenticationData = {
        Username: email,
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
        Username: email,
        Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => resolve(session),
            onFailure: (err) => reject(err),
        });
    });
};

export const signOut = (cognitoUser: CognitoUser | null) => {
    if (cognitoUser) {
        cognitoUser.signOut();
    }
};

export const generateUsername = (): string => {
    return `user_${uuidv4()}`;
};

export const generatePassword = (): string => {
    const length = 16;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
};

export const initiateSignUp = async (email: string): Promise<string> => {
    const username = generateUsername();
    const password = generatePassword();

    await fetch(
        `https://cognito-idp.${COGNITO_REGION}.amazonaws.com/`,
        {
            method: 'POST',
            headers: {
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp',
                'Content-Type': 'application/x-amz-json-1.1',
            },
            body: JSON.stringify({
                ClientId: COGNITO_APP_CLIENT_ID,
                Username: username,
                Password: password,
                UserAttributes: [
                    {
                        Name: 'email',
                        Value: email
                    }
                ]
            })
        }
    );

    return username;
};

export const confirmSignUp = async (username: string, code: string): Promise<void> => {
    await fetch(
        `https://cognito-idp.${COGNITO_REGION}.amazonaws.com/`,
        {
            method: 'POST',
            headers: {
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmSignUp',
                'Content-Type': 'application/x-amz-json-1.1',
            },
            body: JSON.stringify({
                ClientId: COGNITO_APP_CLIENT_ID,
                Username: username,
                ConfirmationCode: code,
            })
        }
    );
};

export const initiatePasswordReset = async (email: string): Promise<void> => {
    const userData = {
        Username: email,
        Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: () => resolve(),
            onFailure: (err) => reject(err),
        });
    });
};

export const confirmPasswordReset = async (email: string, code: string, newPassword: string): Promise<void> => {
    const userData = {
        Username: email,
        Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => resolve(),
            onFailure: (err) => reject(err),
        });
    });
};

export const getCognitoUser = (email: string): CognitoUser => {
    const userData = {
        Username: email,
        Pool: userPool
    };
    return new CognitoUser(userData);
};

export const getSession = async (cognitoUser: CognitoUser): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
                console.error('Session error:', err);
                reject(new Error(`セッションの取得に失敗しました: ${err.message}`));
                return;
            }
            if (!session) {
                console.error('No active session');
                reject(new Error('アクティブなセッションがありません。再度サインインしてください。'));
                return;
            }
            resolve(session);
        });
    });
};

export const getCurrentUser = (): CognitoUser | null => {
    return userPool.getCurrentUser();
};
