import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';

interface ResetPasswordFormProps {
    onInitiateReset: (email: string) => void;
    onConfirmReset: (email: string, code: string, newPassword: string) => void;
    onSignInClick: () => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
    onInitiateReset,
    onConfirmReset,
    onSignInClick
}) => {
    const [email, setEmail] = useState('');
    const [stage, setStage] = useState<'initiate' | 'confirm'>('initiate');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleInitiateSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onInitiateReset(email);
        setStage('confirm');
    };

    const handleConfirmSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('パスワードが一致しません');
            return;
        }
        onConfirmReset(email, code, newPassword);
    };

    if (stage === 'initiate') {
        return (
            <form onSubmit={handleInitiateSubmit}>
                <Typography variant="h6" gutterBottom>パスワードリセット</Typography>
                <TextField
                    fullWidth
                    label="メールアドレス"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    確認コードを送信
                </Button>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={onSignInClick} color="primary">
                        サインインに戻る
                    </Button>
                </Box>
            </form>
        );
    }

    return (
        <form onSubmit={handleConfirmSubmit}>
            <Typography variant="h6" gutterBottom>新しいパスワードの設定</Typography>
            {error && (
                <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>
            )}
            <TextField
                fullWidth
                label="確認コード"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                margin="normal"
            />
            <TextField
                fullWidth
                type="password"
                label="新しいパスワード"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                margin="normal"
            />
            <TextField
                fullWidth
                type="password"
                label="新しいパスワード（確認）"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                パスワードを変更
            </Button>
        </form>
    );
};

export default ResetPasswordForm;
